<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown
    vs-custom-content
    vs
    vs-trigger-click
    class="cursor-pointer"
    @click.native="ActivarEstadoNotificaciones"
  >
    <!-- <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 sm:mr-6 mr-2"
      :badge="notificacionesFilter"
    /> -->

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
      <div vs class="notification-top text-center p-5 bg-primary text-white">
        <h3
          class="text-white"
          v-if="getNotifyState.notifications && getNotifyState.notifications.length == 0"
        >No Tiene Nuevas notificaciones</h3>
        <h3
          class="text-white"
          v-if="getNotifyState.notifications && getNotifyState.notifications.length > 0"
        >{{ getNotifyState.notifications.length }} Nuevas</h3>
        <p
          class="opacity-75"
          v-if="getNotifyState.notifications && getNotifyState.notifications.length > 0"
        >App Notifications</p>
      </div>
      <VuePerfectScrollbar
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="bordered-items">
          <li
            v-for="ntf in ultimasNotificaciones"
            :key="ntf.index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
          >
            <div class="flex items-start">
              <feather-icon
                :icon="ntf.icon"
                :svgClasses="[`text-${ntf.type}`, 'stroke-current mr-1 h-6 w-6']"
              ></feather-icon>
              <div class="mx-2">
                <span
                  class="font-medium block notification-title"
                  :class="[`text-${ntf.type}`]"
                >{{ ntf.name }}</span>
                <small>{{ ntf.description }}</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ fechaFormateada(ntf.date_created) }}</small>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div
        class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"
        @click="paginaNotificaciones"
      >
        <span>Ver Historial</span>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import gql from "graphql-tag";

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      getUser: [],
      getNotifyState: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  apollo: {
    getUser: {
      query: gql`
        query($id: ID!) {
          getUser(id: $id) {
            _id
            notifications {
              name
              description
              icon
              date_created
              type
            }
          }
        }
      `,
      variables() {
        return {
          id: localStorage.user_id
        };
      },
      pollInterval: 700
    },
    getNotifyState: {
      query: gql`
        query($id: ID!) {
          getNotifyState(id: $id) {
            notifications {
              name
              estado
            }
          }
        }
      `,
      variables() {
        return {
          id: localStorage.user_id
        };
      },
      pollInterval: 700
    }
  },
  methods: {
    paginaNotificaciones() {
      this.$router.push("/notificaciones");
    },
    cambiarEstadoNotificaciones() {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($userID: ID!) {
              updateStateNotification(userID: $userID) {
                first_name
              }
            }
          `,
          // Parameters
          variables: {
            userID: localStorage.user_id
          }
        })
        .then(function() {})
        .catch(function(error) {
          console.log(error);
        });
    },
    ActivarEstadoNotificaciones() {
      const self = this;
      setTimeout(function() {
        self.cambiarEstadoNotificaciones();
      }, 4000);
    },
    fechaFormateada(fecha) {
      let nuevaFecha = new Date(fecha);
      let dd = nuevaFecha.getDate();
      let mm = nuevaFecha.getMonth() + 1;
      let yyy = nuevaFecha.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      let datefull = dd + "/" + mm + "/" + yyy;

      return datefull;
    }
  },
  computed: {
    ultimasNotificaciones() {
      let ultimosRegistros;
      const notificaciones = this.getUser.notifications;
      if (notificaciones) {
        if(notificaciones.length > 5){
          ultimosRegistros = notificaciones.slice(notificaciones.length - 5);
        }else{
          ultimosRegistros = notificaciones;
        }
        
        ultimosRegistros.sort(function(a,b){
          return(
            new Date(b.date_created) - new Date(a.date_created)
          )
        });
      }

      return ultimosRegistros;
    },
    notificacionesFilter() {
      const notificaciones = this.getNotifyState.notifications;
      let existeNotifiacion;
      if (notificaciones && notificaciones.length > 0) {
        existeNotifiacion = notificaciones.length;
      } else {
        existeNotifiacion = 0;
      }
      return existeNotifiacion;
    }
  }
};
</script>

