import store from "../../../store/store";

const { role } = store.getters["auth/getUser"];
let permisos;
if (role == "SELLER") {
  permisos = [
    {
      header: "Ventas",
      icon: "PackageIcon",
      i18n: "Apps",
      items: [
        {
          url: "/panel-desarrolladora",
          name: "Proyectos",
          slug: "Inicio",
          icon: "GridIcon"
        },
        //   {
        //     url: "/buscador",
        // name: "Buscador",
        // slug: "Buscador",
        // icon: "SearchIcon",
        //   },
        {
          tag: "0",
          tagColor: "success",
          url: "/comparador",
          name: "comparador",
          slug: "Comparador",
          icon: "CopyIcon"
        },
        {
          url: "/cotizaciones",
          name: "Cotizaciones",
          slug: "Cotizaciones Generadas",
          icon: "FileTextIcon"
        },
        {
          url: "/reservas",
          name: "reservation",
          slug: "reservation",
          icon: "FlagIcon",
          translate: true
        }
      ]
    }
  ];
} 
else if (role == "SUPERVISOR") {
  permisos = [
    {
      header: "Ventas",
      icon: "PackageIcon",
      i18n: "Apps",
      items: [
        {
          url: "/panel-desarrolladora",
          name: "Proyectos",
          slug: "Inicio",
          icon: "GridIcon"
        },
        {
          tag: "0",
          tagColor: "success",
          url: "/comparador",
          name: "comparador",
          slug: "Comparador",
          icon: "CopyIcon"
        },
        {
          url: "/cotizaciones",
          name: "Cotizaciones",
          slug: "Cotizaciones Generadas",
          icon: "FileTextIcon"
        },
        {
          url: "/reservas",
          name: "reservation",
          slug: "reservation",
          icon: "FlagIcon",
          translate: true
        }
      ]
    },
    {
      header: "Administración",
      icon: "Edit3Icon",
      i18n: "FormsAndTable",
      items: [
        {
          url: "/equipo-comercial",
          name: "Equipo comercial",
          slug: "Equipo Comercial",
          icon: "UsersIcon"
        },
        {
          url: "/proyectos",
          name: "Editar Proyectos",
          slug: "Proyectos",
          icon: "EditIcon"
        },
        {
          url: "/opcionados",
          name: "optioned",
          slug: "optioned",
          icon: "UnlockIcon",
          translate: true
        },
      ]
    }
  ];
}
else if (role == "ADMIN") {
  permisos = [
    {
      header: "Ventas",
      icon: "PackageIcon",
      i18n: "Apps",
      items: [
        {
          url: "/panel-desarrolladora",
          name: "Proyectos",
          slug: "Inicio",
          icon: "GridIcon"
        },
        //   {
        //     url: "/buscador",
        // name: "Buscador",
        // slug: "Buscador",
        // icon: "SearchIcon",
        //   },
        {
          tag: "0",
          tagColor: "success",
          url: "/comparador",
          name: "comparador",
          slug: "Comparador",
          icon: "CopyIcon"
        },
        {
          url: "/cotizaciones",
          name: "Cotizaciones",
          slug: "Cotizaciones Generadas",
          icon: "FileTextIcon"
        },
        {
          url: "/reservas",
          name: "reservation",
          slug: "reservation",
          icon: "CheckSquareIcon",
          translate: true
        }
      ]
    },
    // {
    //   header: "Resultados",
    //   icon: "LayersIcon",
    //   i18n: "UI",
    //   items: [
    //     {
    //       url: "/datos-actuales",
    //       name: "Datos actuales",
    //       slug: "Datos Actuales",
    //       icon: "TrelloIcon",
    //     },

    //   ]
    // },
    {
      header: "Administración",
      icon: "Edit3Icon",
      i18n: "FormsAndTable",
      items: [
        // {
        //   url: "/autorizaciones",
        //   name: "Autorizaciones",
        //   slug: "Soliciudes Especiales",
        //   icon: "CheckCircleIcon",
        // },
        {
          url: "/equipo-comercial",
          name: "Equipo comercial",
          slug: "Equipo Comercial",
          icon: "UsersIcon"
        },
        {
          url: "/proyectos",
          name: "Editar Proyectos",
          slug: "Proyectos",
          icon: "EditIcon"
        },
        {
          url: "/opcionados",
          name: "optioned",
          slug: "optioned",
          icon: "UnlockIcon",
          translate: true
        },
        {
          url: "/perfil-empresarial",
          name: "Perfil Empresarial",
          slug: "Perfil Empresarial",
          icon: "BriefcaseIcon"
        }
      ]
    }
  ];
}

export default permisos;
